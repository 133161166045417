body {
    margin: 0;
}

@font-face {
    font-family: "Roboto Regular";
    src: url("../fonts/roboto/Roboto-Regular.ttf") format("truetype");
}

@font-face {
    font-family: "Roboto Bold";
    src: url("../fonts/roboto/Roboto-Bold.ttf") format("truetype");
}